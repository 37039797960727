<template>
  <div class="policy-box" v-nk-loading="loading">
    <div class="news-wrap page-width">
      <div class="left-search">
        <el-col class="col-wrap left-search-class" :span="12" style="min-width: 300px; width:100%">
         <el-menu active-text-color="#4285F7" :default-active="activeIndex" class="el-menu-vertical-demo" @select="handleSelect">
			<el-submenu index="1">
				<template slot="title">
					<span>服务条款及政策</span>
				</template>
				<el-menu-item-group>
					<el-menu-item index="riderAgreement">骑手用户协议</el-menu-item>
					<el-menu-item index="userProtocol">GOGO2GO用户协议</el-menu-item>
					<el-menu-item index="riderPrivacyAgreement">骑手隐私政策</el-menu-item>
					<el-menu-item index="privacyPolicy">GOGO2GO隐私政策</el-menu-item>
					<el-menu-item index="merchantPrivacy">商家隐私政策</el-menu-item>
					<el-menu-item index="offerDescription">优惠说明</el-menu-item>
				</el-menu-item-group>
			</el-submenu>
		 </el-menu>
        </el-col>
      </div>
      <div class="compileCss" v-html="detailsData"></div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  data() {
    return {
      input2: '',
      isDynamic: false,
      detailsData: '',
      documentArr: [],
      loading: false,
	  activeIndex:'riderAgreement',
    }
  },
  mounted() {
	this.getHtml();
  },
  methods: {
	getHtml(){
		this.loading=true;
		axios.get('https://my.api.admin.gogo2go.com/sysProtocolContent/get/'+this.activeIndex).then(res=>{
			this.detailsData='';
			if(res.data.code==200){
				this.detailsData=res.data.data.context;
			}
			this.loading=false;
		}).catch(()=>{
			this.loading=false;
		})
	},
	handleSelect(key){
		this.activeIndex=key;
		this.getHtml();
	}
  }
}
</script>

<style scoped>
.bannerData .el-tabs__header {
  margin-bottom: 0 !important;
}

.col-wrap .el-menu {
  border: none !important;
}

@media screen and (max-width: 997px) {
  .news-wrap {
    flex-direction: column;
    padding: 50px 10px !important;
  }

  .news-wrap .compileCss {
    width: auto;
    margin-left: 0;
  }

  .page-width {
    width: 90%;
  }

  .left-search {
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(136, 136, 136, 0.1);
  }
}
</style>
